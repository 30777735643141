.SettingHolidayDetailContainer {
    .ant-select {
        .ant-select-selector {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
            max-height: 120px;
            overflow: auto;

            .ant-input-affix-wrapper {
                min-height: 40px;
                border-radius: 5px;
            }
        }
    }

    .ant-picker.ant-picker-range,
    .ant-picker {
        min-height: 40px;
        border-radius: 5px;
        align-items: center;
    }

    .ant-input-affix-wrapper {
        min-height: 40px;
        border-radius: 5px;
        align-items: center;
    }

    td {
        vertical-align: top;
    }

    .holiday-name,
    .holiday-date {
        width: 18%;
    }

    .cutoff-date {
        width: 13%;
    }

    .actions {
        vertical-align: middle;
    }

    .store {
        width: 25%;
    }

    .product-type {
        width: 25%;
    }

    .store,
    .product-type {
        .select1 .ant-select-selector {
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
            background: #e9e8e8;
            max-height: 88px !important;
        }

        .select2 .ant-select-selector {
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            max-height: 88px !important;
        }

        .isDelete .ant-select-selection-item-remove {
            display: none !important;
        }
    }

    .cutoff-tag {
        .ant-select-selector {
            border-radius: 5px !important;
            max-height: 88px !important;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}