.ProductSource {
  .Label {
    min-width: 100px;
  }

  .Quantity .Number {
    font-size: 2rem;
    font-weight: bold;
  }

  .CopyTitleMap {
    position: absolute;
    right: 0;
    z-index: 9;
  }

  .variantTitle {
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;

    .textHover {
      transition: all 0.3s ease-in-out;
    }

    .textHover:hover {
      text-decoration: none;
      color: #0062cc !important;
    }
  }
}